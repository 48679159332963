import React from 'react'
import styled from 'styled-components'
import { Spring } from 'react-spring/renderprops'
import { useSpring, animated } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'

import Layout from '../components/layout'
import HomeBanner from '../images/home-banner.jpg'

import {
  StyledLink,
  StyledSpan,
  StyledH2,
  StyledP,
} from '../styled-components/base'
import { EndPoint } from '../components/nav/header-nav'

const HeroImage = styled.div`
  background-image: url(${HomeBanner});
  box-shadow: inset 0 0 0 2000px rgba(0, 143, 153, 0.3);
  padding: 2rem;
`

const StyledStencil = styled.div`
  font-family: 'Allerta Stencil', serif;
`

const HeroSection = () => {
  return (
    <HeroImage className="bg-center h-auto mx-auto max-w-screen-xl px-4sm:px-6">
      <div className="text-center">
        <StyledH2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
          Committed to improving <br className="xl:hidden" />
          <StyledSpan className="text-primary-200">
            your health, mental toughness, and well being
          </StyledSpan>
        </StyledH2>
        <StyledP className="mt-3 max-w-md mx-auto text-base text-primary-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          We&apos;ve dealt with thousands of clients and transformed them not
          just physically but mentally as well. We remain in pursuit of finding
          people ready to commit to themselves by making their physical and
          mental health a priority.
        </StyledP>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <StyledLink
              to={EndPoint.CORTIZ_FITNESS_HOME}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:shadow-outline-primary"
            >
              Personal Fitness
            </StyledLink>
          </div>
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <StyledLink
              to="/fit-kidz-4-fun"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-secondary-600 bg-white hover:text-primary-500 focus:outline-none focus:shadow-outline-blue"
            >
              Kids Fitness
            </StyledLink>
          </div>
        </div>
      </div>
    </HeroImage>
  )
}

const ServicesProvided = () => {
  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="text-lg leading-6 text-primary-600 font-semibold tracking-wide uppercase">
            Services Provided
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            A better way to strengthen your physical and mental health
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            Dedicated to enhancing your personal growth
          </p>
        </div>

        <div className="mt-10">
          <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-secondary-500 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M11.978 17c-.948.011-1.529.896-1.978 1.608a278.009 278.009 0 01-2.909 4.529c-.312.475-.65.859-1.219.863-.57.004-1.052-.685-.777-1.265C5.826 21.196 9 15.658 9 11c0-1.613-.858-2-2-2H3a1 1 0 010-2h18a1 1 0 010 2h-4c-1.141 0-2 .387-2 2 0 4.658 3.175 10.196 3.906 11.735.275.58-.207 1.269-.777 1.265-.569-.004-.908-.388-1.219-.863A284.737 284.737 0 0114 18.608c-.451-.717-1.038-1.611-2-1.608h-.022zM12 0a3 3 0 110 6 3 3 0 010-6" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4 shadow p-4 bg-white rounded">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    Personal Training
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    This training service focuses on achieving your personal
                    goals. We&apos;ll concentrate solely on pushing your limits
                    during these one on one sessions so that we can channel your
                    best self.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-secondary-500 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8 24l2.674-9h-9.674l16-15-2.674 9h8.674l-15 15zm-1.586-11h6.912l-1.326 4 5.739-6h-6.065l1.304-4-6.564 6z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4 shadow p-4 bg-white rounded">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    Sports and Agility Training
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    We&apos;ll do an assesment to see where you currently stand
                    so we can identify where we can make improvements on speed
                    and agility conditioning
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-secondary-500 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M20.624 22.474L19 21.605l-1.625.869.324-1.813-1.328-1.277 1.825-.252.804-1.658.804 1.658 1.825.252-1.329 1.277.324 1.813zm-7 0L12 21.605l-1.625.869.325-1.813-1.329-1.277 1.825-.252.804-1.658.804 1.658 1.825.252-1.329 1.277.324 1.813zm-7 0L5 21.605l-1.625.869.325-1.813-1.329-1.277 1.825-.252L5 17.474l.804 1.658 1.825.252L6.3 20.661l.324 1.813zM23 15.974H1a1 1 0 00-1 1v6a1 1 0 001 1h22a1 1 0 001-1v-6a1 1 0 00-1-1zm-22.998-1H5c.008-1.214-.001-2.289 0-3.013.005-3.993 1.749-3.116 1.749-6.663 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.62 1.359-1.91 1.657C.074 10.018 0 10.807 0 11.753l.002 3.221zM22.808 9.76c-1.29-.298-2.491-.558-1.91-1.657 1.77-3.342.47-5.129-1.399-5.129-1.265 0-2.248.817-2.248 2.324 0 3.324 1.719 2.704 1.749 6.676.008.972-.009 1.311 0 3h4.998L24 11.753c0-.946-.074-1.735-1.192-1.993zm-4.811 5.214H6.002L6 11.848c0-1.258.1-2.482 1.588-2.826 1.684-.389 3.344-.736 2.545-2.209-2.366-4.364-.674-6.839 1.866-6.839 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.569 1.589 2.829l-.002 3.123z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4 shadow p-4 bg-white rounded">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    Group Training Classes
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Let the enthusiasm and energy of others in a group session
                    fuel your desire to accomplish more. These sessions are tons
                    of fun and help build an accountability system
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-secondary-500 text-white">
                    <svg
                      className="h-6 w-6"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path d="M9.082 2.303A2.996 2.996 0 0112 0a2.997 2.997 0 012.923 2.316c1.701.422 3.117 1.306 4.056 2.81.748 1.199 1.054 2.393 1.553 6.089 1.002.265 1.634.865 1.912 1.76.263.846.516 3.591.554 5.029.035 1.473-.395 2.64-1.881 3.131l-.01.003c-.498 3.078-3.527 2.848-4.527 2.859-1.444.009-2.692-.004-4.576-.011-1.198.004-5.325.013-5.804-.016-1.984-.158-3.03-1.228-3.297-2.829-1.144-.363-1.904-1.3-1.904-2.805 0-1.27.205-3.603.482-5.056.233-1.083.886-1.775 1.994-2.067.47-3.648.804-5.189 1.868-6.588.951-1.251 2.245-1.956 3.739-2.322M6.255 7.126c-.436 1.241-.839 4.666-.991 5.915-.331.02-.67.032-1.004.062-.688.073-.765.255-.845.727-.274 1.602-.413 3.459-.415 4.565.01.593.204.797.695.887.156.027.929.09 1.1.105.083 2.411.128 2.588 2.649 2.611 1.335.008 4.56-.011 4.56-.011 2.637.01 3.49.021 4.862.008 2.281-.022 2.273-.42 2.347-2.607.332-.029.664-.053.995-.091.836-.118.812-.542.784-1.39a31.41 31.41 0 00-.425-4.195c-.068-.341-.178-.486-.569-.57-.274-.062-.97-.085-1.252-.102-.124-1-.548-4.579-.991-5.852-.877-2.523-3.084-3.19-5.777-3.19-2.65 0-4.843.628-5.723 3.128m11.746 10.863c-.012 1.923-.901 2.937-2.888 2.998a322.1 322.1 0 01-6.217 0C7 20.926 6.042 19.823 6 18.059v-4.068h12.001v3.998zm-7-2.998h-4c0 1.036-.023 2.071.001 3.106.045 1.318.711 1.85 1.915 1.89 2.059.021 4.118.019 6.176 0 1.383-.043 1.895-.565 1.909-2.001v-2.995h-4.001v2.998a1.001 1.001 0 01-2 0v-2.998zm.446-8.196c-1.944.149-2.953.773-3.213 5.208-.062.632-.961.629-1-.019.013-.702.153-1.945.351-2.804.359-1.542 1.033-2.742 2.543-3.185.974-.286 2.781-.285 3.749 0 1.455.426 2.133 1.555 2.496 3.037.244 1 .392 2.656.366 3.016a.5.5 0 01-.993.01c-.306-3.096-.336-5.126-3.255-5.267l.676 2.335a1.195 1.195 0 11-2.337.025l.617-2.356zm.554 1.994a.598.598 0 110 1.196.598.598 0 010-1.196m1.791-6.683A1.985 1.985 0 0011.995.999c-.814.01-1.46.46-1.783 1.102a15.367 15.367 0 013.58.005" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4 shadow p-4 bg-white rounded">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    After School Sports and Fitness Program
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Our one of a kind Sports and Fitness program is located
                    currently in Florida for kids ages 5-12. We&apos;re in
                    community centers and over 23 OCPS schools in Orlando.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const Guarantee = () => {
  const [hasSeenAnimation, setHasSeenAnimation] = React.useState(() => false)
  return (
    <div className="bg-gray-50">
      <div className="bg-gray-50 pt-12 sm:pt-16">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Trusted throughout Central Florida
            </h2>
            <p className="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
              Known for producing incredible results
            </p>
          </div>
        </div>
        <div className="mt-10 pb-12 bg-white sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
            <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <div className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <>
                        <animated.div className="border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                          <Spring
                            from={{ number: 0 }}
                            to={{
                              number: isVisible ? 546 : 0,
                            }}
                            onRest={() => setHasSeenAnimation(true)}
                          >
                            {({ number: clients }) => (
                              <>
                                <p className="text-5xl leading-none font-extrabold text-primary-500">
                                  <animated.span className="text-md">
                                    {hasSeenAnimation
                                      ? 546
                                      : (
                                          Math.round(clients * 100) / 100
                                        ).toFixed(0)}
                                  </animated.span>
                                  <span className="text-accent-400">+</span>
                                </p>
                                <p className="mt-2 text-lg leading-6 font-medium text-gray-500">
                                  Clients
                                </p>
                              </>
                            )}
                          </Spring>
                        </animated.div>

                        <div className="border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                          <Spring
                            from={{ number: 0 }}
                            to={{ number: isVisible ? 23 : 0 }}
                          >
                            {({ number: schools }) => (
                              <>
                                <p className="text-5xl leading-none font-extrabold text-primary-500">
                                  <animated.span className="text-md">
                                    {hasSeenAnimation
                                      ? 23
                                      : (
                                          Math.round(schools * 100) / 100
                                        ).toFixed(0)}
                                  </animated.span>
                                  <span className="text-accent-400">+</span>
                                </p>
                                <p className="mt-2 text-lg leading-6 font-medium text-gray-500">
                                  Schools
                                </p>
                              </>
                            )}
                          </Spring>
                        </div>
                        <div className="border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                          <Spring
                            from={{ number: 0 }}
                            to={{ number: isVisible ? 856 : 0 }}
                          >
                            {({ number: students }) => (
                              <>
                                <p className="text-5xl leading-none font-extrabold text-primary-500">
                                  <animated.span className="text-md">
                                    {hasSeenAnimation
                                      ? 856
                                      : (
                                          Math.round(students * 100) / 100
                                        ).toFixed(0)}
                                  </animated.span>
                                  <span className="text-accent-400">+</span>
                                </p>
                                <p className="mt-2 text-lg leading-6 font-medium text-gray-500">
                                  Students
                                </p>
                              </>
                            )}
                          </Spring>
                        </div>
                      </>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Testimonials = () => {
  return (
    <div className="bg-primary-600">
      <div className="max-w-screen-xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-primary-900 lg:pr-16">
          <div className="md:flex-shrink-0">
            <StyledStencil className="text-4xl font-bold text-primary-800">
              Cortiz Fitness
            </StyledStencil>
          </div>
          <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg leading-7 font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-primary-500"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                I came to Christian when I was at 340 pounds and after 4 months
                I went down to 280 lb. I just want to say to him thank you and I
                will hope to see him soon, he is the best, he has helped my
                self-esteem and he has made a tremendous impact in my life.
                Great motivator and he is a nutritionist.
              </p>
            </div>
            <footer className="mt-4">
              <div className="flex">
                <div className="ml-4">
                  <div className="text-base leading-6 font-medium text-white">
                    <em>Angel L. Berrios</em>
                  </div>
                  <span className="text-sm leading-6 font-medium text-primary-200">
                    <em>joined 2018</em>
                  </span>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
        <div className="py-12 px-4 border-t-2 border-primary-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
          <div className="md:flex-shrink-0">
            <StyledStencil className="text-4xl font-bold text-primary-800">
              Fit Kidz 4 Fun
            </StyledStencil>
          </div>
          <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg leading-7 font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-primary-500"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                Just wanted to say how comfortable my kids and I feel with this
                company In their after school program. They have amazing
                customer service and very easy to get ahold. My kids look
                forward to what&apos;s next each week at their program where
                they offer sports and physical activities for kids.
              </p>
            </div>
            <footer className="mt-4">
              <div className="flex">
                <div className="ml-4">
                  <div className="text-base leading-6 font-medium text-white">
                    <em>Phil Miller</em>
                  </div>
                  <span className="text-sm leading-6 font-medium text-primary-200">
                    <em>joined 2019</em>
                  </span>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  )
}

const IndexPage = () => {
  return (
    <Layout seoTitle="Home">
      <HeroSection />
      <ServicesProvided />
      <Guarantee />
      <Testimonials />
    </Layout>
  )
}

export default IndexPage
